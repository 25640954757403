export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    logs: 'USER_LOGS_HANDLER',
  },
  update: 'USER_UPDATE',
};

export const CATALOGS = {
  handlers: {
    settings: 'CATALOGS_QUERY_SETTINGS_HANDLER',
  },
  app: 'CATALOGS_APP',
};

export const FAQ = {
  handlers: {
    summary: 'FAQ_SUMMARY_HANDLER',
    topic: 'FAQ_TOPIC_HANDLER',
  },
  summary: 'FAQ_FETCH',
  topic: 'FAQ_TOPIC',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const SIGN_OUT = 'SIGN_OUT';
