import axios from 'axios';
import { compact } from 'lodash';

import { BACKEND } from '../config';

export async function callAPI(path, params, isDownload = false) {
  const options = { withCredentials: true };
  if (isDownload) options.responseType = 'blob';
  const result = await axios.post(`${BACKEND}/${path}`, params, options);
  return result.data;
}

export async function uploadFiles(path, params, files) {
  const data = new FormData();
  Object.entries(params).forEach(([field, val]) => {
    data.append(field, val);
  });
  files.forEach((file) => {
    data.append('files', file);
  });
  const result = await axios.post(`${BACKEND}/${path}`, data, {
    withCredentials: true,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  return result.data ?? '';
}

export const signedOut = () => {
  axios.post(`${BACKEND}/api/signedOut`, {}, { withCredentials: true }).catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
  });
};

export async function graphQLCaller(source, collection, query, variables) {
  try {
    const res = await axios.post(
      `${BACKEND}/${source}/${collection}`,
      {
        query,
        variables,
      },
      {
        withCredentials: true,
      }
    );
    return res.data?.data ?? {};
  } catch (err) {
    throw Error(
      compact((err.response?.data?.errors || []).map(({ message }) => message)).join('. ') ||
        'Unkown error'
    );
  }
}
