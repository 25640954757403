import { Pane } from 'evergreen-ui';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

function ResponsiveLayout({ renderItem, size }) {
  const smallScreen = useMediaQuery({ maxWidth: 400 });
  const mediumScreen = useMediaQuery({ maxWidth: 1000, minWidth: 400 });

  const columns = useMemo(() => {
    if (smallScreen) return 1;
    if (mediumScreen) return 2;
    return 3;
  }, [smallScreen, mediumScreen]);

  const rows = useMemo(() => range(Math.ceil(size / columns)), [columns, size]);

  return (
    <Pane>
      {rows.map((row) => (
        <Pane key={row} display="flex" justifyContent="space-around">
          {range(columns).map((col) => (
            <Pane width={window.innerWidth / columns}>
              {row * columns + col < size ? renderItem(row * columns + col) : null}
            </Pane>
          ))}
        </Pane>
      ))}
    </Pane>
  );
}

ResponsiveLayout.propTypes = {
  renderItem: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
};

export default ResponsiveLayout;
