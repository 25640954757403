import { Tab, Tablist } from 'evergreen-ui';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { handleGetSummary } from '../../redux/actions/faq';
import Waiting from '../Waiting';

function Roles() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { product, topic, role } = useParams();
  const { settings, summary, handling } = useSelector(({ catalogs, faq }) => ({
    settings: catalogs.app?.settings?.data ?? {},
    summary: faq.summary ?? {},
    handling: faq.handling ?? false,
  }));

  useEffect(() => {
    if (!role && Object.keys(settings.roles || {}).length)
      history.push(`/${settings.id}/${Object.keys(settings.roles)[0]}`);
  }, [settings.roles, role]);

  useEffect(() => {
    if (settings.id && role && !summary[role]) dispatch(handleGetSummary(role));
  }, [settings.id, role]);

  const getLink = useCallback(
    (id) => {
      const prefix = `/${product}/${id}`;
      if (topic) return `${prefix}/${topic}`;
      return prefix;
    },
    [product, topic]
  );

  return (
    <Tablist marginBottom={10}>
      {handling ? <Waiting /> : null}
      {Object.keys(settings.roles ?? {}).map((id) => (
        <Tab key={id} id={id} onSelect={() => history.push(getLink(id))} isSelected={role === id}>
          {settings.roles?.[id] ?? id}
        </Tab>
      ))}
    </Tablist>
  );
}

export default Roles;
