import { toaster } from 'evergreen-ui';
import { all, put, takeLatest } from 'redux-saga/effects';

import { callAPI } from '../../libs/backend';
import { CATALOGS } from './constants';

function* updateApp(id, data) {
  yield put({
    type: CATALOGS.app,
    id,
    data,
  });
}

function flattenTopics(topics) {
  let flattened = {};
  Object.entries(topics).forEach(([id, values]) => {
    flattened[id] = values;
    if (values.subtopics) {
      flattened = {
        ...flattened,
        ...flattenTopics(values.subtopics),
      };
    }
  });
  return flattened;
}
function* querySettings({ id }) {
  try {
    const { _id, ...data } = yield callAPI('faqs/get', { id }) || {};
    if (_id) {
      yield* updateApp('settings', {
        id,
        ...data,
        flattenTopics: flattenTopics(data.topics ?? {}),
      });
    } else {
      toaster.warning('Không tìm thấy cấu hình');
      yield* updateApp('settings', {});
    }
  } catch ({ message }) {
    toaster.danger(message);
    yield* updateApp('settings', {});
  }
}

export const handleGetSettings = (id) => ({
  type: CATALOGS.handlers.settings,
  id,
});

export default function* saga() {
  yield all([yield takeLatest(CATALOGS.handlers.settings, querySettings)]);
}
