import { ChevronRightIcon, Icon, Link, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

function Breadcrumbs({ links }) {
  return (
    <Pane display="flex">
      {links.map(({ title, onClick }, index) => (
        <Pane key={index} display="flex" alignItems="center">
          <Link
            color="neutral"
            onClick={onClick}
            textDecoration={onClick && index + 1 < links.length ? 'underline' : 'none'}
            cursor="pointer"
          >
            {title}
          </Link>
          {index + 1 < links.length ? <Icon icon={ChevronRightIcon} color="#696f8c" /> : null}
        </Pane>
      ))}
    </Pane>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string.isRequired,
    })
  ),
};

Breadcrumbs.defaultProps = {
  links: [],
};

export default Breadcrumbs;
