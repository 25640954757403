import { combineReducers } from 'redux';

import catalogs from './catalogs';
import faq from './FAQ';
import settings from './settings';

const rootReducer = combineReducers({
  settings,
  catalogs,
  faq,
});

export default rootReducer;
