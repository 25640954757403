/* eslint-disable import/prefer-default-export */
import { Pane } from 'evergreen-ui';
import styled from 'styled-components';

const HeaderWrapper = styled(Pane)`
  @media (max-width: 600px) {
    div:first-child {
      height: 100px;
      flex-direction: column;
      width: unset;
      margin: 0 0;
      div:first-child {
        flex-direction: row;
        height: 30px;
      }
    }
  }
`;

export { HeaderWrapper };
