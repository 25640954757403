import { Heading, Pane, Paragraph } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

import Divider from '../Divider';

function ArticleItem({ onClick, title, subtitle }) {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      marginTop={5}
      marginBottom={5}
      paddingTop={24}
      paddingBottom={24}
      paddingLeft={5}
      paddingRight={5}
      height="100%"
    >
      <Pane marginBottom={10}>
        <Heading
          size={500}
          marginBottom={4}
          textDecoration="underline"
          cursor="pointer"
          onClick={onClick}
        >
          {title}
        </Heading>
        <Paragraph color="#717171">{subtitle}</Paragraph>
      </Pane>
      <Divider />
    </Pane>
  );
}

ArticleItem.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default ArticleItem;
