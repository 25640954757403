import { defaultTheme } from 'evergreen-ui';
import { cloneDeep, set } from 'lodash';

const refTheme = cloneDeep(defaultTheme);
set(refTheme, 'components.Link.baseStyle.color', (_, { color }) => {
  switch (color) {
    case 'neutral':
      return 'gray700';
    case 'green':
      return 'green500';
    default:
      return 'blue500';
  }
});

export { refTheme };
