import { ArrowUpIcon, IconButton, ThemeProvider } from 'evergreen-ui';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';

import { Waiting } from './components';
import { refTheme } from './Themes';

const Home = React.lazy(() => import('./screens/Home'));
const Topics = React.lazy(() => import('./screens/Topics'));
const NotFound = React.lazy(() => import('./screens/NotFound'));

export default function App() {
  const [isVisible, setIsVisible] = useState();
  const toggleVisibility = () => {
    if (window.pageYOffset > 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', () => {
      toggleVisibility();
    });
  }, []);

  return (
    <ThemeProvider value={refTheme}>
      <BrowserRouter>
        <Suspense fallback={<Waiting />}>
          <RouterSwitch>
            <Route exact path="/:product/:role?" component={Home} />
            <Route exact path="/:product/:role/:topic?/:article?" component={Topics} />
            <Route component={() => <NotFound />} />
          </RouterSwitch>
        </Suspense>
      </BrowserRouter>
      {isVisible ? (
        <IconButton
          style={{
            position: 'fixed',
            right: '1rem',
            bottom: '10%',
            width: 45,
            height: 45,
            left: 'unset',
            borderRadius: '50% 50%',
          }}
          icon={ArrowUpIcon}
          onClick={() => scrollToTop()}
          intent="success"
          appearance="primary"
        />
      ) : null}
    </ThemeProvider>
  );
}
