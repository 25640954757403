import { FAQ, SIGN_OUT } from '../actions/constants';

const initialState = {};

export default function faq(state = initialState, action) {
  const { data, role, id, type } = action;
  switch (type) {
    case FAQ.handlers.summary:
    case FAQ.handlers.topic:
      return {
        ...state,
        handling: true,
      };
    case FAQ.summary:
      return {
        ...state,
        summary: {
          ...(state.summary ?? {}),
          [role]: data,
        },
        handling: false,
      };
    case FAQ.topic:
      return {
        ...state,
        topics: {
          ...(state.topics ?? {}),
          [id]: {
            ...(state.topics?.[id] ?? {}),
            [role]: data,
          },
        },
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
