import { toaster } from 'evergreen-ui';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { callAPI } from '../../libs/backend';
import { FAQ } from './constants';

function* updateSummary(role, data = {}) {
  yield put({
    type: FAQ.summary,
    role,
    data,
  });
}

function* updateTopic(id, role, data = []) {
  yield put({
    type: FAQ.topic,
    id,
    role,
    data,
  });
}

function* getSummary({ role }) {
  try {
    const faq = (yield select())?.catalogs?.app?.settings?.data?.id ?? '';
    const articles = (yield callAPI('faqs/summary', { faq, role }) || []).map(
      ({ _id, ...data }) => ({ id: _id, ...data })
    );
    yield* updateSummary(role, {
      top: articles.filter((e) => e.top),
      gettingStarted: articles.filter((e) => e.gettingStarted),
    });
  } catch ({ message }) {
    toaster.danger(message);
    yield* updateSummary(role);
  }
}

function* getTopicArticles({ id, role }) {
  try {
    const faq = (yield select())?.catalogs?.app?.settings?.data?.id ?? '';
    const articles = yield callAPI('faqs/topicArticles', { faq, topic: id, role }) || [];
    yield* updateTopic(
      id,
      role,
      articles.map(({ _id, ...data }) => ({ id: _id, ...data }))
    );
  } catch ({ message }) {
    toaster.danger(message);
    yield* updateTopic(id, role);
  }
}

export const handleGetSummary = (role) => ({
  type: FAQ.handlers.summary,
  role,
});

export const handleGetTopicArticles = (id, role) => ({
  type: FAQ.handlers.topic,
  id,
  role,
});

export default function* saga() {
  yield all([
    yield takeLatest(FAQ.handlers.summary, getSummary),
    yield takeEvery(FAQ.handlers.topic, getTopicArticles),
  ]);
}
