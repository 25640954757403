import { CATALOGS, SIGN_OUT } from '../actions/constants';

const initialState = {
  system: {},
  app: {},
};

export default function catalogs(state = initialState, action) {
  const { app } = state;
  const { id, data } = action;

  switch (action.type) {
    case CATALOGS.handlers.settings:
      return {
        ...state,
        app: {
          ...app,
          faq: {
            handling: true,
          },
        },
      };
    case CATALOGS.app:
      return {
        ...state,
        app: {
          ...app,
          [id]: {
            data: data || (app?.[id]?.data ?? {}),
            handling: false,
          },
        },
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
