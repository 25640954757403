import { Heading, Pane, SearchInput } from 'evergreen-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { RESOURCES } from '../../config';
import { handleGetSettings } from '../../redux/actions/catalogs';
import Divider from '../Divider';
import Waiting from '../Waiting';
import { HeaderWrapper } from './styles';

function AppBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { product } = useParams();
  const { settings, handling } = useSelector(({ catalogs, faq }) => ({
    settings: catalogs.app?.settings?.data ?? {},
    summary: faq.summary ?? {},
    handling: catalogs.app?.settings?.handling ?? false,
  }));

  useEffect(() => {
    if (!settings.id || settings.id !== product) dispatch(handleGetSettings(product));
  }, [settings.id, product]);

  return (
    <HeaderWrapper width="100%" height={60} position="sticky" zIndex={10} boxSizing="border-box">
      <Pane
        display="flex"
        paddingTop={10}
        paddingBottom={10}
        marginLeft={65}
        marginRight={65}
        height={80}
        alignItems="center"
      >
        {handling ? <Waiting /> : null}
        <Pane
          display="flex"
          flexGrow={1}
          alignItems="center"
          width={150}
          cursor="pointer"
          onClick={() => history.push(`/${product}`)}
          marginLeft={80}
        >
          <img style={{ width: 50, height: 'auto' }} src={`${RESOURCES}/xbot.png`} alt="logo" />
          <Heading size={600}>Trung Tâm Trợ Giúp</Heading>
        </Pane>
        <SearchInput placeholder="Tìm kiếm hướng dẫn" />
      </Pane>
      <Divider />
    </HeaderWrapper>
  );
}

export default AppBar;
